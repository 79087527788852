<template>
  <div id="UPMMReport" class="items-center justify-center inset-0 ...">
    <div
      class="p-8 lg:w-1/3 w-full"
      style="
        background-color: white;
        border-radius: 10px;
        margin: auto !important;
      "
    >
      <div class="vx-card__title mb-4"></div>

      <div class="vx-row lg:w-full">
        <div class="vx-col text-center lg:w-1/2 w-full">
          <v-select
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
            class="w-full mb-4"
            label="Name"
            v-model="documentModel.DocumentID"
            :options="DocumentTypes"
            :reduce="(ID) => ID.ID"
            :placeholder="$t('DocumentType')"
          />
        </div>
        <div
          class="vx-col text-center lg:w-1/2 w-full"
          v-if="documentModel.DocumentID == 1"
        >
          <v-select
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
            class="w-full mb-10"
            label="Name"
            v-model="documentModel.DocumentTypeID"
            :options="ScanTypes"
            :reduce="(ID) => ID.ID"
            :placeholder="$t('ScanType')"
          />
        </div>
        <div
          class="vx-col lg:w-1/2 w-full text-center"
          v-if="documentModel.DocumentID == 2"
        >
          <v-select
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
            class="w-full mb-10"
            label="Name"
            v-model="documentModel.DocumentTypeID"
            :options="AnalysisTypes"
            :reduce="(ID) => ID.ID"
            :placeholder="$t('AnalysisType')"
          />
        </div>
        <div
          class="vx-col text-center lg:w-1/2 w-full"
          v-if="documentModel.DocumentID == 3"
        >
          <v-select
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
            class="w-full mb-10"
            label="Name"
            v-model="documentModel.DocumentTypeID"
            :options="ReportTypes"
            :reduce="(ID) => ID.ID"
            :placeholder="$t('ReportType')"
          />
        </div>
        <div class="w-full lg:w-full md:w-/3 ml-3">
          <vs-input
            class="w-full"
            v-model="documentModel.Description"
            :placeholder="$t('Description')"
          />
        </div>
      </div>

      <div class="vx-col w-full md:w-full mx-auto self-center d-theme-dark-bg">
        <vs-upload
          class="w-full text-center ml-8"
          automatic
          :limit="1"
          :action="baseURL + 'api/Common/UploadFile'"
          :headers="UploadRequestHeader"
          @on-success="successUpload"
        />

        <div class="vx-row text-center">
          <vs-button
            class="w-full"
            color="primary"
            v-if="
              uploaded &&
              documentModel.DocumentID > 0 &&
              documentModel.DocumentTypeID > 0 &&
              documentModel.Description"
            @click="AddAttachmnet"
            >{{ $t("Procced") }}</vs-button
          >
        </div>
        <div class="vx-row text-center">
          <vs-button
            class="w-full ml-20 mr-20 mb-10"
            type="line"
            color="black"
            @click="$emit('closePop')"
            >{{ $t("Back") }}</vs-button
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import modulePatient from "@/store/Patient/modulePatient.js";
import { domain, mainSetting } from "@/gloabelConstant.js";
import axios from "@/axios.js";
import moduleDocumentType from "@/store/settings/documentType/moduleDocumentType.js";
import moduleAnalysisType from "@/store/settings/analysisType/moduleAnalysisType.js";
import moduleReportType from "@/store/settings/reportType/moduleReportType.js";
import moduleScanType from "@/store/settings/scanType/moduleScanType.js";

export default {
  data() {
    return {
      UploadRequestHeader: mainSetting.UploadRequestHeader,
      uploadUrl: domain + "api/Common/UploadFile",
      baseURL: domain,
      ShowDocumentUpload: false,
      uploaded: false,
      ShowChooseFile: false,
    };
  },
  props: {
    documentModel: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    currentPage() {
      if (this.isMounted) {
        debugger;
        return this.$refs.table.currentx;
      }
      return 0;
    },
    patientMedical() {
      return this.$store.state.patientList.pendingPatientMedicalReport;
    },
    DocumentTypes() {
      return this.$store.state.DocumentTypeList.DocumentTypes;
    },
    ScanTypes() {
      return this.$store.state.ScanTypeList.ScanTypes;
    },
    ReportTypes() {
      return this.$store.state.ReportTypeList.ReportTypes;
    },
    AnalysisTypes() {
      return this.$store.state.AnalysisTypeList.AnalysisTypes;
    },
  },
  methods: {
    updateRequest(data) {
      debugger;
      this.ShowDocumentUpload = true;
      this.documentModel.DoctorID = data.DoctorID;
      this.documentModel.PatientReservationSessionID =
        data.PatientReservationSessionID;
      this.documentModel.PatientReservationSurgeryID =
        data.PatientReservationSurgeryID;
      this.documentModel.IsRequest = true;
      this.documentModel.PatientMedicalReportRequestID = data.ID;
    },
    AddAttachmnet() {
      debugger;
      this.documentModel.PatientID = this.$store.state.AppActiveUser.Patient.ID;
      this.$store
        .dispatch("patientList/AddPatientMedicalDocument", this.documentModel)
        .then((res) => {
          if (res.status == 200) {
            window.showSuccess(res.data.message);
            // this.searchData()
            document.getElementsByClassName("btn-x-file")[0].click();
            this.uploaded = false;
            debugger
            this.$emit("saveUploaded",res.data.Data);
          }
        })
        .catch(() => {
          window.showError();
        });
    },
    successUpload(event) {
      this.documentModel.AttachPath = event.target.responseText;
      window.showAddSuccess();
      this.uploaded = true;
    },

    searchData() {
      debugger;
      if (this.$store.state.AppActiveUser.Patient.ID) {
        var model = {};
        model.patientID = this.$store.state.AppActiveUser.Patient.ID;
        model.StausID = 1; //pending
        this.$store
          .dispatch("patientList/PatientMedicalReportRequest", model)
          .then((res) => {
            if (res.status == 200) {
              this.Model = res.data.Data;
            }
          })
          .catch(() => {
            this.$vs.loading.close();
          });
      }
    },
  },
  created() {
    if (!moduleScanType.isRegistered) {
      this.$store.registerModule("ScanTypeList", moduleScanType);
      moduleScanType.isRegistered = true;
    }

    this.$store.dispatch("ScanTypeList/GetAllScanTypes");
    if (!moduleReportType.isRegistered) {
      this.$store.registerModule("ReportTypeList", moduleReportType);
      moduleReportType.isRegistered = true;
    }

    this.$store.dispatch("ReportTypeList/GetAllReportTypes");
    if (!moduleAnalysisType.isRegistered) {
      this.$store.registerModule("AnalysisTypeList", moduleAnalysisType);
      moduleAnalysisType.isRegistered = true;
    }

    this.$store.dispatch("AnalysisTypeList/GetAllAnalysisTypes");
    if (!moduleDocumentType.isRegistered) {
      this.$store.registerModule("DocumentTypeList", moduleDocumentType);
      moduleDocumentType.isRegistered = true;
    }

    this.$store.dispatch("DocumentTypeList/GetAllDocumentTypes");
    if (!modulePatient.isRegistered) {
      this.$store.registerModule("patientList", modulePatient);
      modulePatient.isRegistered = true;
    }
    // this.searchData();
  },
};
</script>
<style>
.con-slot-tabs {
  margin-top: 1rem;
}
.background {
  color: rgb(255, 9, 9);
}

.head {
  color: white;
  text-align: center;
  margin-top: 20px;
}
</style>
